$(document).foundation();

$("#ReservierungForm").on("submit", function (event) {
    if (event.isDefaultPrevented()) {
        // handle the invalid form...
    } else {
        // everything looks good!
        event.preventDefault();
        submitForm();

    }
});


function submitForm() {
    // Initiate Variables With Form Content
    var name = $("#Name").val();
    var date = $("#Date").val();
    var time = $("#Time").val();
    var sumguest = $("#SumGuest").val();
    var phone = $("#Phone").val();
    var email = $("#Mail").val();
    var message = $("#Message").val();

    $.ajax({
        type: "POST",
        url: "assets/php/reserv-mail.php",
        data: "name=" + name + "&date=" + date + "&time=" + time + "&sumguest=" + sumguest + "&phone=" + phone + "&email=" + email + "&message=" + message,
        success: function (text) {
            if (text == "success") {
                formSuccess();
                console.log("text == success");
            }
        }
    });
}
function formSuccess() {
    console.log("function formSuccess");
    $("#msgSubmit").removeClass("hide");
}